import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Account Dashboard Intro" prev={{
  name: 'Account Initialization Process',
  path: '/account-initialization-process'
}} next={{
  name: 'Send Review Requests',
  path: '/send-review-requests'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Your account dashboard is where you control Simple RM.`}</p>
    <p>{`You must complete the `}<a parentName="p" {...{
        "href": "/help-center/account-setup/"
      }}>{`account setup`}</a>{` flow to be able to access the account dashboard.`}</p>
    <p>{`We will briefly discuss the four main pages of the account dashboard in this article. `}</p>
    <h2 {...{
      "id": "home-page"
    }}>{`Home page`}</h2>
    <p>{`When you first create your account the `}<a target="_blank" href='https://app.simplereviewmanagement.com'>{`home page`}</a>{` features a large section that describes how to get started with Simple RM. Once you are up and running that section is replaced by live stats for your account.`}</p>
    <p>{`The Home page also includes quick access to a collection of commonly used features.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "/help-center/customer-experience-preview/"
      }}>{`preview your customers’ experience`}</a>{`.`}</p>
    <p>{`You can start a `}<a parentName="p" {...{
        "href": "/help-center/bulk-customer-import/"
      }}>{`bulk customer import`}</a>{`.`}</p>
    <p>{`You can contact us to ask questions or give feedback. Please don’t hesitate to use it. We love to hear what you have to say!`}</p>
    <h2 {...{
      "id": "customers-page"
    }}>{`Customers page`}</h2>
    <p>{`The `}<a target="_blank" href='https://app.simplereviewmanagement.com/customers'>{`customer page`}</a>{` is where you view and manage all the customers in your account.`}</p>
    <p>{`You can view:`}</p>
    <ul>
      <li parentName="ul">{`Customer information`}</li>
      <li parentName="ul">{`The status of customers`}</li>
      <li parentName="ul">{`Feedback from customers (if your are using the Reputation Monitoring add-on)`}</li>
    </ul>
    <p>{`This is where you would:`}</p>
    <ul>
      <li parentName="ul">{`Add new customers `}</li>
      <li parentName="ul">{`Search for customers`}</li>
      <li parentName="ul">{`Edit customer information`}</li>
      <li parentName="ul">{`Trigger a review request for a customer`}</li>
      <li parentName="ul">{`Prevent a customer from receiving a review request`}</li>
    </ul>
    <h2 {...{
      "id": "activity-page"
    }}>{`Activity page`}</h2>
    <p>{`The `}<a target="_blank" href='https://app.simplereviewmanagement.com/activity'>{`activity page`}</a>{` has a timeline that shows all the significant events that occur for your account. These include`}</p>
    <ul>
      <li parentName="ul">{`Review request email being sent`}</li>
      <li parentName="ul">{`Review request SMS text message being sent `}</li>
      <li parentName="ul">{`Customer providing feedback `}</li>
      <li parentName="ul">{`Customer posting a Google Review`}</li>
    </ul>
    <h2 {...{
      "id": "settings-page"
    }}>{`Settings page`}</h2>
    <p>{`The `}<a target="_blank" href='https://app.simplereviewmanagement.com/settings'>{`setting page`}</a>{` is broken down into three tabs, General, Integrations and Notifications.`}</p>
    <h4 {...{
      "id": "general-tab"
    }}>{`General tab`}</h4>
    <p>{`If you have a QuickBooks Online account connected to your Simple RM account the `}<a parentName="p" {...{
        "href": "/help-center/auto-send-setting/"
      }}>{`auto send setting`}</a>{` will be located at the top of the page.`}</p>
    <p>{`Below that, or at the top of the page if you do not have QuickBooks Online connected, is the Review Request Display section.`}</p>
    <p>{`The Review Request Display section is where you set company information that is used in the review requests customers see. Things like company name, logo, colour and custom email signature.`}</p>
    <p>{`There is also a preview button on the right side of this section that opens up the `}<a parentName="p" {...{
        "href": "/help-center/customer-experience-preview/"
      }}>{`customer experience preview`}</a>{` section so you can preview the changes you make.`}</p>
    <p>{`At the bottom of the page is the Review Request Options section. In this section there are a few settings that control how your review requests behave.`}</p>
    <p>{`Here you can choose if you want to send review requests as SMS text messages, emails or both.`}</p>
    <p>{`If you choose both, the customer will be contacted through one of the two methods depending on what contact information they have. If they have both a phone number and an email address, they will receive an email.`}</p>
    <p>{`This is also where the ”First name required for review request” setting is located. This setting determines if customers must have a first name in order to be eligible to be sent a review request.`}</p>
    <h4 {...{
      "id": "integrations-tab"
    }}>{`Integrations tab`}</h4>
    <p>{`The integrations tab is where you go if you want to connect Simple RM to another of your business accounts when you have already completed the account setup flow.`}</p>
    <p>{`It is also where you can manage settings specific to any of the connections you have.`}</p>
    <p>{`Lastly, it is where you can remove a connection with one of your other business accounts.`}</p>
    <h4 {...{
      "id": "notifications-tab"
    }}>{`Notifications tab`}</h4>
    <p>{`There are multiple types of notifications sent by Simple RM.`}</p>
    <p>{`This tab is where you configure which notifications you receive. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      